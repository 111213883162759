import React from "react";
import Trombinoscope from '../../../assets/icons/trombinoscope.svg';

function Header() {
    return (
        <div className="page-header d-flex align-items-center p-1 justify-content-between rounded bg-light">
            <h1 className="position-absolute start-50 m-auto">Trombinoscope</h1>
            <img src={Trombinoscope} alt="Logo notes" className="ms-auto" style={{width : "40px"}} />
        </div>
    );
}

export default Header;
