import axios from "axios";
import {AuthContextProps} from "react-oidc-context";
import { toast } from "react-toastify";
import { StudentInfo } from "../interfaces/StudentInfo";

export class UserService {
    static async GetStudentInfoByLogin(auth: AuthContextProps, studentLogin: String): Promise<StudentInfo> {
        try {
            const response = await axios.get<StudentInfo>(`${process.env.REACT_APP_SCOLARITE_API_URL}/student/${studentLogin}`, { headers: { Authorization: `Bearer ${auth.user?.access_token}` } });
            console.log(response.data);
            return response.data;
        } catch (error) {
            toast.error("Aucun utilisateur trouvé avec cet identifiant.");
            throw error;
        }
    }

    static async UpdateStudentInfo(auth: AuthContextProps, studentLogin: String, studentInfo: StudentInfo): Promise<StudentInfo> {
        try {
            console.log(studentInfo);
            const response = await axios.patch<StudentInfo>(`${process.env.REACT_APP_SCOLARITE_API_URL}/student/${studentLogin}`, studentInfo, { headers: { Authorization: `Bearer ${auth.user?.access_token}` } });
            return response.data;
        } catch (error) {
            toast.error("Erreur lors de la modification des données personnelles.");
            throw error;
        }
    }
}
