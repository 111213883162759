import React, { useEffect, useState } from "react";
import { Student } from "./StudentInterface";
import TableRow from "./TableRow";
import axios from "axios";
import { toast } from "react-toastify";
import { useAuth } from "react-oidc-context";

interface TableViewProps {
  searchTerm: string;
  columns: { key: string; label: string; visible: boolean }[];
}

export default function TableView({ searchTerm, columns }: TableViewProps) {
    const [currentPage, setCurrentPage] = useState(1);
    const [students, setStudents] = useState<Student[]>([]);
    const studentsPerPage = 10;
    const auth = useAuth();

    useEffect(() => {
        const fetchStudents = async () => {
            try {
                const res = await axios.get(
                    `${process.env.REACT_APP_SCOLARITE_API_URL}/student/students`,
                    {
                        headers: { Authorization: `Bearer ${auth.user?.access_token}` },
                        params: { promotion: auth.user?.profile.promotion },
                    }
                );
                setStudents(res.data);
            } catch (err: any) {
                toast.error(err.message);
            }
        };

        fetchStudents();
    }, [auth.user?.access_token, auth.user?.profile.promotion]);

    // Filtres
    const filteredStudents = students.filter(
        (student) =>
            student.login.toLowerCase().includes(searchTerm.toLowerCase()) ||
        student.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        student.lastName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        student.email.toLowerCase().includes(searchTerm.toLowerCase())
    );

    // Pagination
    const indexOfLastStudent = currentPage * studentsPerPage;
    const indexOfFirstStudent = indexOfLastStudent - studentsPerPage;
    const currentStudents = filteredStudents.slice(
        indexOfFirstStudent,
        indexOfLastStudent
    );

    const totalPages = Math.ceil(filteredStudents.length / studentsPerPage);

    const handlePageChange = (pageNumber: number) => {
        setCurrentPage(pageNumber);
    };

    const optionColumns = columns.reduce((acc, col) => {
        acc[col.key] = col.visible;
        return acc;
    }, {} as any);

    return (
        <div className="d-flex flex-column">
            {/* Table */}
            <div className="container mt-4 flex-grow-1">
                <table className="table table-striped table-hover">
                    <thead className="table-light">
                        <tr>
                            {optionColumns.name && <th style={{ width: "20%" }}>Nom</th>}
                            {optionColumns.phone && (
                                <th style={{ width: "13.33%" }} className="text-center">
                  Téléphone
                                </th>
                            )}
                            {optionColumns.group && (
                                <th style={{ width: "18.33%" }} className="text-center">
                  Équipe
                                </th>
                            )}
                            {optionColumns.pae && (
                                <th style={{ width: "8.33%" }} className="text-center">
                  PAE
                                </th>
                            )}
                            {optionColumns.cec && (
                                <th style={{ width: "13.33%" }} className="text-center">
                  CEC
                                </th>
                            )}
                            {optionColumns.international && (
                                <th style={{ width: "13.33%" }} className="text-center">
                  International
                                </th>
                            )}
                            {optionColumns.linkedin && (
                                <th style={{ width: "13.33%" }} className="text-center">
                  LinkedIn
                                </th>
                            )}
                        </tr>
                    </thead>
                    <tbody>
                        {currentStudents.map((student, index) => (
                            <TableRow
                                key={index}
                                student={student}
                                optionColumns={optionColumns}
                            />
                        ))}
                    </tbody>
                </table>
            </div>

            {/* Pagination */}
            <nav className="d-flex justify-content-center mt-auto mb-4">
                <ul className="pagination">
                    <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
                        <button
                            className="page-link"
                            onClick={() => handlePageChange(currentPage - 1)}
                        >
              &laquo;
                        </button>
                    </li>
                    {Array.from({ length: totalPages }, (_, i) => (
                        <li
                            key={i + 1}
                            className={`page-item ${currentPage === i + 1 ? "active" : ""}`}
                        >
                            <button
                                className="page-link"
                                onClick={() => handlePageChange(i + 1)}
                            >
                                {i + 1}
                            </button>
                        </li>
                    ))}
                    <li
                        className={`page-item ${
                            currentPage === totalPages ? "disabled" : ""
                        }`}
                    >
                        <button
                            className="page-link"
                            onClick={() => handlePageChange(currentPage + 1)}
                        >
              &raquo;
                        </button>
                    </li>
                </ul>
            </nav>
        </div>
    );
}
