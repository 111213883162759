import React from "react";
import HeaderUserInfo from "./components/common/Header";
import GeneralView from "./views/GeneralView";

function UserInfoRouter() {
    return (
        <>
            <HeaderUserInfo/> 
            <GeneralView/>
        </>
    );
}

export default UserInfoRouter;
