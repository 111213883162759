import React from "react";
import { FaLinkedin } from "react-icons/fa";
import { Student } from "./StudentInterface";

export default function TableRow({
    student,
    optionColumns,
}: {
  student: Student;
  optionColumns: any;
}) {
    return (
        <tr>
            {optionColumns.name && (
                <td>
                    <div className="d-flex align-items-center">
                        <img
                            src={
                                student.profile
                                    ? student.profile
                                    : `https://ui-avatars.com/api/?name=${
                                        student.firstName + " " + student.lastName
                                    }&background=0D8ABC&color=fff`
                            }
                            style={{ width: "40px", height: "40px" }}
                            alt="profile"
                            className="rounded-circle me-2"
                        />
                        <div>
                            <strong>{student.firstName + " " + student.lastName} </strong>
                            <br />
                            {student.email ? (
                                student.email
                            ) : (
                                <span className="badge bg-secondary">Non renseigné</span>
                            )}
                        </div>
                    </div>
                </td>
            )}
            {optionColumns.phone && (
                <td className="text-center align-middle">
                    {student.phoneNumber ? (
                        <span className="badge bg-dark">{student.phoneNumber}</span>
                    ) : (
                        <span className="badge bg-secondary">Non renseigné</span>
                    )}
                </td>
            )}
            {optionColumns.group && (
                <td className="text-center align-middle">
                    <span className="badge bg-success">{student.group}</span>
                </td>
            )}
            {optionColumns.pae && (
                <td className="text-left align-middle">
                    {student.groupPAE ? (
                        <span className="badge bg-info">{student.groupPAE}</span>
                    ) : (
                        <span className="badge bg-secondary">Non renseigné</span>
                    )}
                </td>
            )}
            {optionColumns.cec && (
                <td className="text-center align-middle">
                    {student.groupCEC ? (
                        <span className="badge bg-secondary">{student.groupCEC}</span>
                    ) : (
                        <span className="badge bg-secondary">Non renseigné</span>
                    )}
                </td>
            )}
            {optionColumns.international && (
                <td className="text-center align-middle">
                    {student.abroadSemester ? (
                        <span className="badge bg-danger">{student.abroadSemester}</span>
                    ) : (
                        <span className="badge bg-secondary">Non renseigné</span>
                    )
                    }
                </td>
            )}
            {optionColumns.linkedin && (
                <td className="text-center align-middle">
                    {student.linkedin ? (
                        <a href={student.linkedin}>
                            <FaLinkedin size={28} />
                        </a>
                    ) : (
                        <span className="badge bg-secondary">Non renseigné</span>
                    )}
                </td>
            )}
        </tr>
    );
}
