import React from "react";
import { useAuth } from "react-oidc-context";
import { useState } from "react";
import { Group } from "../interfaces/AuthentificationInterface";
import UserInfoRouter from "../components/UserInfo/UserInfoRouter";

const getHighestRole = (userGroups: Group[]): Group => {
    if (userGroups.includes(Group.MajorLeader)) {
        return Group.MajorLeader;
    } else if (userGroups.includes(Group.Teacher)) {
        return Group.Teacher;
    }

    return Group.Student;
};

export const ViewContext = React.createContext({
    role: Group.Student,
    setRole: (role: Group) => {},
});

function UserInfo() {
    const auth = useAuth();
    const userGroups = auth.user?.profile["group"] as Array<Group>;

    const [role, setRole] = useState<Group>(getHighestRole(userGroups));
    const value = { role, setRole };
    
    return (
        <ViewContext.Provider value={value}>
            <div>
                <UserInfoRouter />
            </div>
        </ViewContext.Provider>
    );
}

export default UserInfo;
