import React, { useState } from "react";
import Header from "../components/shared/trombinoscope/Header";
import { Spinner } from "react-bootstrap";
import { useAuth } from "react-oidc-context";
import { Group } from "../interfaces/AuthentificationInterface";
import TableView from "../components/shared/trombinoscope/TableView";
import GalleryView from "../components/shared/trombinoscope/GalleryView";
import FilterBar from "../components/shared/trombinoscope/FilterBar";
import ColumnOptions from "../components/shared/trombinoscope/ColumnOptions";

const DEFAULT_COLUMNS = [
    { key: "name", label: "Nom", visible: true },
    { key: "phone", label: "Téléphone", visible: true },
    { key: "group", label: "Équipe", visible: true },
    { key: "pae", label: "PAE", visible: true },
    { key: "cec", label: "CEC", visible: true },
    { key: "international", label: "International", visible: true },
    { key: "linkedin", label: "LinkedIn", visible: true },
];


export default function Trombinoscope() {
    const auth = useAuth();
    const userGroup = auth.user?.profile.group as Array<Group>;
    const [viewMode, setViewMode] = useState("table");
    const [searchTerm, setSearchTerm] = useState("");
    const [columns, setColumns] = useState(DEFAULT_COLUMNS);

    const toggleColumnVisibility = (key: string) => {
        setColumns((prev) =>
            prev.map((col) =>
                col.key === key ? { ...col, visible: !col.visible } : col
            )
        );
    };

    
    if (
        userGroup.includes(Group.MajorLeader) ||
        userGroup.includes(Group.Teacher)
    ) {
        return (
            <div className="d-flex flex-column">
                <Header />
                <div className="d-flex align-items-center mt-2 px-3">
                    <button
                        className="btn btn-light"
                        onClick={() =>
                            setViewMode(viewMode === "table" ? "gallery" : "table")
                        }
                    >
                        {viewMode === "table" ? "Galerie" : "Tableau"}
                    </button>

                    {viewMode === "table" && (
                        <ColumnOptions
                            columns={columns}
                            toggleColumnVisibility={toggleColumnVisibility}
                        />
                    )}

                    <div className="flex-grow-1">
                        <FilterBar
                            searchTerm={searchTerm}
                            setSearchTerm={setSearchTerm}
                        />
                    </div>
                </div>

                <div className="flex-grow-1 mt-2">
                    {viewMode === "table" ? (
                        <TableView searchTerm={searchTerm} columns={columns} />
                    ) : (
                        <GalleryView searchTerm={searchTerm} />
                    )}
                </div>
            </div>
        );
    } else if (userGroup.includes(Group.Student)) {
        const studentId = auth.user?.profile.preferred_username;
        if (studentId === undefined) {
            return <Spinner animation="border" variant="primary" />;
        }
        return (
            <div className="d-flex flex-column">
                <Header />
                <div className="d-flex align-items-center mt-2">
                    <button
                        className="btn btn-light px-3"
                        onClick={() =>
                            setViewMode(viewMode === "table" ? "gallery" : "table")
                        }
                    >
                        {viewMode === "table" ? "Galerie" : "Tableau"}
                    </button>

                    {viewMode === "table" && (
                        <ColumnOptions
                            columns={columns}
                            toggleColumnVisibility={toggleColumnVisibility}
                        />
                    )}

                    <div className="flex-grow-1">
                        <FilterBar
                            searchTerm={searchTerm}
                            setSearchTerm={setSearchTerm}
                        />
                    </div>
                </div>

                <div className="flex-grow-1 mt-2">
                    {viewMode === "table" ? (
                        <TableView searchTerm={searchTerm} columns={columns} />
                    ) : (
                        <GalleryView searchTerm={searchTerm} />
                    )}
                </div>
            </div>
        );
    }

    return <Spinner animation="border" variant="primary" />;
}