import React, { useEffect, useState } from "react";
import { CourseWithMean, Grade } from "../../../../interfaces/CourseInterface";
import { Student } from "../../../../interfaces/StudentInterface";
import "./grade.css";
import axios from "axios";
import { useAuth } from "react-oidc-context";
import { Modal, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import {
    ReactGrid,
    Column,
    Row,
    CellChange,
    TextCell,
} from "@silevis/reactgrid";
import "@silevis/reactgrid/styles.css";
import "./custom-reactgrid.scss";

function IndividualTable(props: {
  course: CourseWithMean;
  rows?: Row<TextCell>[];
  students?: Student[];
}) {
    const [isUpdate, setIsUpdate] = useState<boolean>(false);
    const [showDescriptionModal, setShowDescriptionModal] =
    useState<boolean>(false);

    const auth = useAuth();

    const [rows, setRows] = useState<Row<TextCell>[]>([]);
    const [grades, setGrades] = useState<Grade[]>([]);
    const columns: Column[] = [
        { columnId: "Numero", width: 150, resizable: true, reorderable: true },
        { columnId: "student", width: 150, resizable: true, reorderable: true },
        { columnId: "Group", width: 150, resizable: true, reorderable: true },
        { columnId: "grade", width: 150, resizable: true, reorderable: true },
        { columnId: "status", width: 150, resizable: true, reorderable: true },
    ];
    const [students, setStudents] = useState<Student[]>([]);

    const dataRows = (students: Student[], grades: Grade[]): Row<TextCell>[] => {
        return students.map((student, index) => {
            const grade = grades.find(
                (grade) => grade.studentLogin === student.login
            );

            return {
                rowId: index,
                cells: [
                    {
                        type: "text",
                        text: `${index}`,
                        className: "group-cell",
                        readOnly: true,
                    },
                    {
                        type: "text",
                        text: student.firstName + " " + student.lastName,
                        className: "group-cell",
                        readOnly: true,
                        studentLogin: student.login,
                    },
                    {
                        type: "text",
                        text: student.group.toString(),
                        className: "group-cell",
                    },
                    {
                        type: "text",
                        text: grade?.value?.toString() || "",
                        className: "edit-grade",
                    },
                    {
                        type: "text",
                        text: grade?.value ? "✅" : "❌",
                        className: "validation-cell",
                    },
                ],
            };
        });
    };

    const getRows = (students: Student[], grades: Grade[]): Row<TextCell>[] => {
        const headerRow: Row<TextCell> = {
            rowId: "header",
            cells: [
                { type: "text", text: "", className: "reactgrid-header-cell" },
                { type: "text", text: "Étudiant", className: "reactgrid-header-cell" },
                { type: "text", text: "Groupe", className: "reactgrid-header-cell" },
                { type: "text", text: "Note", className: "reactgrid-header-cell" },
                {
                    type: "text",
                    text: "Format valide",
                    className: "reactgrid-header-cell",
                },
            ],
        };

        return [headerRow, ...dataRows(students, grades)];
    };

    useEffect(() => {
        if (props.rows && props.students) {
            const headerRow: Row<TextCell> = {
                rowId: "header",
                cells: [
                    { type: "text", text: "", className: "reactgrid-header-cell" },
                    {
                        type: "text",
                        text: "Étudiant",
                        className: "reactgrid-header-cell",
                    },
                    { type: "text", text: "Groupe", className: "reactgrid-header-cell" },
                    { type: "text", text: "Note", className: "reactgrid-header-cell" },
                    {
                        type: "text",
                        text: "Format valide",
                        className: "reactgrid-header-cell",
                    },
                ],
            };
            const finalRows = [headerRow, ...props.rows];
            setRows(finalRows);
            setStudents(props.students);
            return;
        }

        axios
            .get<Student[]>(
                `${process.env.REACT_APP_SCOLARITE_API_URL}/student/students?promotion=${props.course.promotion}`,
                { headers: { Authorization: `Bearer ${auth.user?.access_token}` } }
            )
            .then(
                (students) => {
                    students.data.sort((a, b) => {
                        return a.login.localeCompare(b.login);
                    });
                    axios
                        .get<Grade[]>(
                            `${process.env.REACT_APP_NOTE_API_URL}/v1/grades/${props.course.courseId}`,
                            {
                                headers: { Authorization: `Bearer ${auth.user?.access_token}` },
                            }
                        )
                        .then((response) => {
                            if (response.data && response.data.length !== 0) {
                                setIsUpdate(true);
                            }
                            setStudents(students.data);
                            setRows(getRows(students.data, response.data));
                            setGrades(response.data);
                        });
                },
                (error) => {
                    toast.error("Une erreur est survenue");
                }
            );
    }, [auth.user?.access_token, props]);

    function handleChanges(changes: CellChange[]) {
        const updatedRows = [...rows];

        changes.forEach(({ rowId, columnId, newCell }) => {
            const rowIndex = updatedRows.findIndex((row) => row.rowId === rowId);
            const colIndex = columns.findIndex((col) => col.columnId === columnId);

            if (colIndex !== 3 || updatedRows[rowIndex].cells[3].text === "Note") {
                return;
            } 
            const gradeCell = newCell as TextCell;
            const statusCell = updatedRows[rowIndex].cells[4] as TextCell;
            if (!gradeCell.text || gradeCell.text.trim() === "") {
                statusCell.text = "❌";
            } else {
                const gradeText = gradeCell.text.trim().replace(",", ".");
                const isValidNumber = /^-?\d+(\.\d+)?$/.test(gradeText);
                const grade = parseFloat(gradeText);
                statusCell.text =
            !isValidNumber || isNaN(grade) || grade < 0 || grade > 20 ? "❌" : "✅";
            }

            updatedRows[rowIndex].cells[3] = gradeCell;
            updatedRows[rowIndex].cells[4] = statusCell;
      
        });

        setRows(updatedRows);
    }

    function handleSubmitButton() {
        if (isUpdate) {
            setShowDescriptionModal(true);
        } else {
            submitGrades();
        }
    }

    function getStudentLogin(name: string) {
        const firstName = name.split(" ")[0].toLowerCase();
        const lastName = name.split(" ")[1].toLowerCase();
        const foundStudent = students.find(
            (student) =>
                student.firstName.toLowerCase() === firstName &&
            student.lastName.toLowerCase() === lastName
        );
        return foundStudent?.login || "";
    }

    function getGradeInformation(studentLogin: string) {
        
        return grades.find((grade) => grade.studentLogin === studentLogin);
    }

    async function submitGrades(description?: string) {
        if (
            !rows.slice(1).reduce((acc, row) => {
                return (
                    (acc && (row.cells[4] as TextCell).text === "✅") ||
          (row.cells[4] as TextCell).text === "Format valide"
                );
            }, true)
        ) {
            return;
        }
        if (isUpdate) {
            if (!description || description === "") {
                return;
            }
            // -- Grade update --

            try {
                const response = await axios.patch(
                    `${process.env.REACT_APP_NOTE_API_URL}/v1/grades/batchUpdate`,
                    {
                        grades: rows.slice(1).map((row) => {
                            const login = getStudentLogin(
                                (row.cells[1] as TextCell).text
                            );
                            return {
                                value: Number((row.cells[3] as TextCell).text),
                                gradeId: getGradeInformation(login)?.gradeId,
                                studentLogin: getStudentLogin(
                                    (row.cells[1] as TextCell).text
                                ),
                                courseId: props.course.courseId,
                            };
                        }),
                        courseId: props.course.courseId,
                        teacherLogin: auth.user?.profile.preferred_username,
                        message: description,
                    },
                    { headers: { Authorization: `Bearer ${auth.user?.access_token}` } }
                );
                if (response.status === 200 || response.status === 201) {
                    toast.success("Les notes ont été modifiées");
                    window.location.reload();
                } else {
                    console.error(response);
                    toast.error("Une erreur est survenue");
                }
            } catch (error) {
                console.error(error);
                toast.error("Une erreur est survenue");
            }
        } else {
            // -- Grade creation --
            try {
                const response = await axios.post(
                    `${process.env.REACT_APP_NOTE_API_URL}/v1/grades/batch`,
                    {
                        grades: rows.slice(1).map((row) => {
                            return {
                                courseId: props.course.courseId,
                                studentLogin: getStudentLogin((row.cells[1] as TextCell).text),
                                value: Number((row.cells[3] as TextCell).text),
                            };
                        }),
                        courseId: props.course.courseId,
                        teacherLogin: auth.user?.profile.preferred_username,
                    },
                    { headers: { Authorization: `Bearer ${auth.user?.access_token}` } }
                );

                if (response.status === 200 || response.status === 201) {
                    toast.success("Les notes ont été modifiées");
                    window.location.reload();
                } else {
                    console.error(response);
                    toast.error("Une erreur est survenue");
                }
            } catch (error) {
                console.error(error);
                toast.error("Une erreur est survenue");
            }
        }
    }

    return (
        <div className="d-flex flex-column reactgrid">
            <ReactGrid
                columns={columns}
                rows={rows}
                onCellsChanged={handleChanges}
                enableRowSelection
                enableColumnSelection
            />
            <Button className="m-2" onClick={handleSubmitButton}>
          Envoyer
            </Button>
            <Modal
                show={showDescriptionModal}
                onHide={() => setShowDescriptionModal(false)}
            >
                <Modal.Header closeButton className="border-0">
                    <Modal.Title>Raison de la modification (obligatoire)</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <textarea id="description" className="w-100" rows={6} />
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-between">
                    <Button
                        variant="success"
                        className="me-1 d-flex"
                        onClick={() =>
                            submitGrades(
                                (
                    document.getElementById(
                        "description"
                    ) as HTMLTextAreaElement
                                ).value
                            )
                        }
                    >
              Envoyer
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default IndividualTable;
