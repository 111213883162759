import React from "react";

interface ColumnOptionsProps {
  columns: { key: string; label: string; visible: boolean }[];
  toggleColumnVisibility: (key: string) => void;
}

export default function ColumnOptions({
    columns,
    toggleColumnVisibility,
}: ColumnOptionsProps) {
    return (
        <div className="dropdown">
            <button
                className="btn btn-light dropdown-toggle mx-2 px-3"
                type="button"
                id="dropdownMenuButton"
                data-bs-toggle="dropdown"
                aria-expanded="false"
            >
          Options
            </button>
            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                {columns.map((col) => (
                    <li key={col.key} className="dropdown-item">
                        <input
                            type="checkbox"
                            checked={col.visible}
                            onChange={() => toggleColumnVisibility(col.key)}
                            className="me-2"
                        />
                        {col.label}
                    </li>
                ))}
            </ul>
        </div>
    );
}
