import React from "react";
import Icon from '../../../../assets/icons/SyllabusIcon.svg';

function HeaderUserInfo() {
    return (
        <div className="page-header d-flex align-items-center border-1 p-2 justify-content-center white-background rounded bg-light my-1">
            <h1 className="position-absolute start-50 m-auto">Info Utilisateur</h1>
            <img src={Icon} alt="Logo UserInfo" className="ms-auto" style={{width : "35px"}} />
        </div>
    );
}

export default HeaderUserInfo;

