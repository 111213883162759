import React, { useEffect, useState } from "react";
import { CourseWithMean } from "../../../../interfaces/CourseInterface";
import axios from "axios";
import { useAuth } from "react-oidc-context";
import { Student } from "../../../../interfaces/StudentInterface";
import { toast } from "react-toastify";
import {
    ReactGrid,
    Column,
    Row,
    CellChange,
    TextCell,
} from "@silevis/reactgrid";
import "@silevis/reactgrid/styles.css";
import "./custom-reactgrid.scss";
import { Button } from "react-bootstrap";
import IndividualTable from "./IndividualTable";

function GroupTable(props: { course: CourseWithMean }) {
    const auth = useAuth();

    const [individualRows, setIndividualRows] = useState<Row<TextCell>[] | null>(
        null
    );
    const [studentsGroups, setStudentsGroups] = useState<{
    [key: string]: Student[];
  }>({});
    const [students, setStudents] = useState<Student[]>([]);

    const [rows, setRows] = useState<Row<TextCell>[]>([]);
    const columns: Column[] = [
        { columnId: "Numero", width: 150, resizable: true, reorderable: true },
        { columnId: "groupName", width: 150, resizable: true, reorderable: true },
        { columnId: "grade", width: 150, resizable: true, reorderable: true },
        { columnId: "status", width: 150, resizable: true, reorderable: true },
    ];

    const headerRow: Row<TextCell> = {
        rowId: "header",
        cells: [
            { type: "text", text: "", className: "reactgrid-header-cell" },
            { type: "text", text: "Groupe", className: "reactgrid-header-cell" },
            { type: "text", text: "Note", className: "reactgrid-header-cell" },
            {
                type: "text",
                text: "Format valide",
                className: "reactgrid-header-cell",
            },
        ],
    };

    const dataRows = (groups: { [key: string]: Student[] }): Row<TextCell>[] => {
        return Object.entries(groups).map(([groupName, students], index) => ({
            rowId: index,
            cells: [
                {
                    type: "text",
                    text: `${index}`,
                    className: "group-cell",
                    readOnly: true,
                },
                {
                    type: "text",
                    text: groupName,
                    className: "group-cell",
                    readOnly: true,
                },
                { type: "text", text: "", className: "edit-grade" },
                {
                    type: "text",
                    text: "❌",
                    className: "validation-cell",
                    readOnly: true,
                },
            ],
        }));
    };

    const getRows = (groups: { [key: string]: Student[] }): Row<TextCell>[] => {
        return [headerRow, ...dataRows(groups)];
    };

    useEffect(() => {
        axios
            .get<Student[]>(
                `${process.env.REACT_APP_SCOLARITE_API_URL}/student/students?promotion=${props.course.promotion}`,
                { headers: { Authorization: `Bearer ${auth.user?.access_token}` } }
            )
            .then((response) => {
                response.data.sort((a, b) => {
                    if (a.group === b.group) return a.login.localeCompare(b.login);
                    return a.group - b.group;
                });
                setStudents(response.data);
                const groups: { [key: string]: Student[] } = response.data.reduce(
                    (groups: { [key: string]: Student[] }, student: Student) => {
                        const groupName = student.groupName;
                        if (!groups[groupName]) {
                            groups[groupName] = [];
                        }
                        groups[groupName].push(student);
                        return groups;
                    },
                    {}
                );
                setRows(getRows(groups));
                setStudentsGroups(groups);
            })
            .catch((error) => {
                toast.error("Une erreur est survenue");
            });
    }, [auth.user?.access_token, props.course.promotion]);

    function handleChanges(changes: CellChange[]) {
        const updatedRows = [...rows];

        changes.forEach(({ rowId, columnId, newCell }) => {
            const rowIndex = updatedRows.findIndex((row) => row.rowId === rowId);
            const colIndex = columns.findIndex((col) => col.columnId === columnId);

            if (colIndex !== 2 || updatedRows[rowIndex].cells[2].text === "Note") {
                return;
            } 
            const gradeCell = newCell as TextCell;
            const statusCell = updatedRows[rowIndex].cells[3] as TextCell;

            if (!gradeCell.text || gradeCell.text.trim() === "") {
                statusCell.text = "❌";
            } else {
                const gradeText = gradeCell.text.trim().replace(",", ".");
                const isValidNumber = /^-?\d+(\.\d+)?$/.test(gradeText);
                const grade = parseFloat(gradeText);
                statusCell.text = !isValidNumber || isNaN(grade) || grade < 0 || grade > 20 ? "❌" : "✅";
            }

            updatedRows[rowIndex].cells[2] = gradeCell;
            updatedRows[rowIndex].cells[3] = statusCell;
      
        });

        setRows(updatedRows);
    }

    function handleSubmitButton() {
        if (
            !rows.slice(1).reduce((acc, row) => {
                return acc && (row.cells[3] as TextCell).text === "✅";
            }, true)
        ) {
            console.log("invalid grades");
            return;
        }

        const individualRows = rows
            .slice(1)
            .map((row) => {
                const groupName = (row.cells[1] as TextCell).text;
                const grade = Number((row.cells[2] as TextCell).text);

                const students = studentsGroups[groupName];

                return students.map((student, studentIndex): Row<TextCell> => {
                    return {
                        rowId: `${student.firstName}.${student.lastName}`,
                        cells: [
                            {
                                type: "text",
                                text: `${studentIndex}`,
                                className: "group-cell",
                            },
                            {
                                type: "text",
                                text: student.firstName + " " + student.lastName,
                                className: "group-cell",
                            },
                            { type: "text", text: student.group.toString(), className: "group-cell" },
                            { type: "text", text: grade.toString(), className: "edit-grade" },
                            { type: "text", text: "✅", className: "group-cell" },
                        ],
                    };
                });
            })
            .flat(1);
        setIndividualRows(individualRows);
    }

    return (
        <>
            {!individualRows && (
                <div className="d-flex flex-column reactgrid">
                    <ReactGrid
                        rows={rows}
                        columns={columns}
                        onCellsChanged={handleChanges}
                        enableRowSelection
                        enableColumnSelection
                    />
                    <Button className="m-2" onClick={handleSubmitButton}>
            Continuer
                    </Button>
                </div>
            )}
            {individualRows && (
                <IndividualTable course={props.course} rows={individualRows} students={students} />
            )}
        </>
    );
}

export default GroupTable;
